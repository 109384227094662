import React, { PureComponent } from 'react';
import PlacesAutocomplete from '@closetbox/react-places-autocomplete';

export default class Region extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { region: '' };
  }

  placesClassNames = {
      input: 'form-control',
      autocompleteContainer: 'autocomplete-list position-absolute',
      autocompleteItem: 'list-group-item',
      autocompleteItemActive: 'list-group-item active',
      googleLogoContainer: 'list-group-item'
  };

  searchOptions = {
    types: ['(regions)'],
  };

  handleChange = region => {
    const { field } = this.props;
    this.setState({ region });
    this.props.onChange({name: field.name, value: region});

  }

  render() {
    const { field } = this.props;
    const { region } = this.state;

    return (
      <>
        <PlacesAutocomplete
          inputProps={{
            value: region,
            onChange: this.handleChange,
          }}
          onBlur={this.handleChange}
          classNames={this.placesClassNames}
          onSelect={this.handleChange}
          clearItemsOnError={true}
          options={this.searchOptions}
          highlightFirstSuggestion={false}
        />
        <input type="hidden" name={field.name} value={region} />
      </>
    );
  }
}
