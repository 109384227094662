import * as yup from 'yup';

import {
  MAX_LENGTH_TEXT_AREA,
  MAX_LENGTH_TEXT_INPUT,
  NOT_EMAIL,
  REQUIRED,
  TOO_LONG,
} from './constants';

export const emailValidation = yup
  .string()
  .email(NOT_EMAIL)
  .required(REQUIRED)
  .max(MAX_LENGTH_TEXT_INPUT, TOO_LONG);

export const textValidation = yup
  .string()
  .required(REQUIRED)
  .max(MAX_LENGTH_TEXT_INPUT, TOO_LONG);

export const textValidationOptional = yup
  .string()
  .max(MAX_LENGTH_TEXT_INPUT, TOO_LONG);

export const textAreaValidation = yup
  .string()
  .required(REQUIRED)
  .max(MAX_LENGTH_TEXT_AREA, TOO_LONG);

export const fedExValidation = yup.string().when('service-type', {
  is: value => {
    return value === 'FedEx';
  },
  then: textValidation,
  otherwise: textValidationOptional,
});
