import React from 'react';

export default () => (
  <div className='row'>
    <div className='col'>
      <button type='submit' className='btn btn-large btn-secondary ml-3'>
        Submit
      </button>
    </div>
  </div>
);
