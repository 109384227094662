import React from 'react';
import Wrapper from '../components/wrapper';
import SampleRequestForm from '../components/sample-request-form';

export default props => {
  const page = props['*'];
  return (
    <Wrapper page={page}>
      <h1 className='d-block d-lg-none page'>Sample Request</h1>
      <div className='row'>
        <div className='col'>
          <SampleRequestForm />
        </div>
      </div>
    </Wrapper>
  );
};
