import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import FormFeedback from './form-feedback';
import FieldRow from './field-row';
import Submit from './submit';

import encode from '../util/encode';
import {
  emailValidation,
  fedExValidation,
  textValidation,
  textValidationOptional,
  textAreaValidation,
} from '../util/validators';

const today = new Date()
const month = today.getMonth();
const year = today.getFullYear();

const validationSchema = yup.object().shape(
  {
    _gotcha: textValidationOptional,
    Email: emailValidation,
    'TEX-E': textValidation,
    'TEX-ELZ': textValidation,
    'Contact Name': textValidation,
    Company: textValidation,
    'Address': textValidation,
    'City': textValidation,
    'State (Province)': textValidation,
    'ZIP (Postal) Code': textValidation,
    Country: textValidation,
    Phone: textValidation,
    'Service Type': textValidation,
    'FedEX Account Number': fedExValidation,
    'FedEx Shipping Method': fedExValidation,
    'What is the intended application?': textAreaValidation,
    'What is the end use product?': textAreaValidation,
    'Where is this product being manufactured?': textAreaValidation,
  },
  [
    '_gotcha',
    'Email',
    'TEX-E',
    'TEX-ELZ',
    'Contact Name',
    'Company',
    'Address',
    'City',
    'State (Province)',
    'ZIP (Postal) Code',
    'Country',
    'Phone',
    'Service Type',
    'FedEX Account Number',
    'FedEx Shipping Method',
    'What is the intended application?',
    'What is the end use product?',
    'Where is this product being manufactured?',
  ],
);

const texEWireSizes = [
  'None',
  '0.20',
  '0.21',
  '0.22',
  '0.23',
  '0.24',
  '0.25',
  '0.26',
  '0.27',
  '0.28',
  '0.29',
  '0.30',
  '0.32',
  '0.35',
  '0.37',
  '0.40',
  '0.45',
  '0.50',
  '0.55',
  '0.60',
  '0.65',
  '0.70',
  '0.75',
  '0.80',
  '0.85',
  '0.90',
  '0.95',
  '1.00',
];

const texElzWireSizes = [
  'None',
  '7/0.10',
  '7/0.11',
  '7/0.12',
  '7/0.13',
  '7/0.14',
  '7/0.15',
  '7/0.16',
  '7/0.17',
  '7/0.18',
  '7/0.19',
  '7/0.20',
  '7/0.21',
  '7/0.22',
  '7/0.23',
  '7/0.24',
  '7/0.25',
  '7/0.26',
  '7/0.27',
  '7/0.28',
  '7/0.29',
  '7/0.30',
];

const fedexShippingMethods = [
  'First Overnight',
  'Priority Overnight',
  'Standard Overnight',
  'FedEx 2Day',
  'FedEx Express Saver',
  'FedEx Ground'
];

const upsShippingMethods = [
  'UPS Next Day Air',
  'UPS 2nd Day Air',
  'UPS 3 Day Select',
  'UPS Ground'
];

const serviceTypes = ['USPS First Class', 'FedEx', 'UPS'];

const initialValues = {
  'form-name': 'sample-request',
  Email: '',
  'TEX-E': 'None',
  'TEX-ELZ': 'None',
  'Contact Name': '',
  Company: '',
  'Address': '',
  'City': '',
  'State (Province)': '',
  'ZIP (Postal) Code': '',
  'Country': '',
  Phone: '',
  'Service Type': serviceTypes[0],
  'FedEX Account Number': '',
  'FedEx Shipping Method': '',
  'What is the intended application?': '',
  'What is the end use product?': '',
  'Where is this product being manufactured?': '',
};

class SampleRequestForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertType: '',
      message: (
        <>
          Please fill out this form for a sample. Samples are typically 30 to 35
          feet and are shipped via the United States Postal Service at our cost.
          If you need it sooner, we can ship via FedEx or UPS at your expense.
          Please include your account number and preferred shipping speed.
          <div className="mt-3">
            {[1, 2].includes(month) && (
              <p>
                <strong>Samples are not available between March 5th and March 30th, {year}.</strong>
              </p>
            )}
          </div>
        </>
      ),
    };
  }

  onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus('submitting');

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode(values),
    })
      .then(() => {
        setSubmitting(false);
        setStatus('success');
        this.setState({
          alertType: 'alert alert-primary',
          message:
            'Thank you, we have received your sample request. We will get back to you soon.',
        });
        window.scrollTo(0, 0);
      })
      .catch(error => {
        console.error('error', error);
        setSubmitting(false);
        setStatus('failure');
        this.setState({
          alertType: 'alert alert-danger',
          message: `There has been an error, please try again or call us. Error: ${error}`,
        });
      });
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <FormFeedback
          alertType={this.state.alertType}
          message={this.state.message}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
          render={formProps => (
            <Form
              name="sample-request"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <Field name="form-name" type="hidden" className="d-none" />
              <div className="row mt-4">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="ml-3">Sample Information</h5>
                      <Field
                        name="TEX-E"
                        label="TEX-E"
                        type="select"
                        options={texEWireSizes}
                        component={FieldRow}
                      />
                      <Field
                        name="TEX-ELZ"
                        label="TEX-ELZ"
                        type="select"
                        options={texElzWireSizes}
                        component={FieldRow}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="ml-3">Shipping Information</h5>
                      <Field
                        name="Contact Name"
                        label="Contact Name"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="Company"
                        label="Company"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="Address"
                        label="Address"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="City"
                        label="City"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="State (Province)"
                        label="State (Province)"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="ZIP (Postal) Code"
                        label="ZIP (Postal) Code"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="Country"
                        label="Country"
                        type="region"
                        component={FieldRow}
                        onChange={field => {
                          formProps.values[field.name] = field.value;
                        }}
                      />
                      <Field
                        name="Phone"
                        label="Phone"
                        type="text"
                        component={FieldRow}
                      />
                      <Field
                        name="Email"
                        label="Email"
                        type="email"
                        component={FieldRow}
                      />
                      <Field
                        name="Service Type"
                        label="Service Type"
                        type="select"
                        options={serviceTypes}
                        component={FieldRow}
                      />
                      {formProps.values['Service Type'] === 'FedEx' && (
                        <>
                          <Field
                            name="FedEX Account Number"
                            label="FedEx Account Number"
                            type="text"
                            component={FieldRow}
                          />
                          <Field
                            name="FedEx Shipping Method"
                            label="FedEx Preferred Shipping Speed"
                            type="select"
                            options={fedexShippingMethods}
                            component={FieldRow}
                          />
                        </>
                      )}
                      {formProps.values['Service Type'] === 'UPS' && (
                        <>
                          <Field
                            name="UPS Account Number"
                            label="UPS Account Number"
                            type="text"
                            component={FieldRow}
                          />
                          <Field
                            name="UPS Shipping Method"
                            label="UPS Preferred Shipping Speed"
                            type="select"
                            options={upsShippingMethods}
                            component={FieldRow}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Field
                        name="What is the intended application?"
                        label="What is the intended application?"
                        type="textarea"
                        component={FieldRow}
                      />
                      <Field
                        name="What is the end use product?"
                        label="What is the end use product?"
                        type="textarea"
                        component={FieldRow}
                      />
                      <Field
                        name="Where is this product being manufactured?"
                        label="Where is this product being manufactured?"
                        type="region"
                        component={FieldRow}
                        onChange={field => {
                          formProps.values[field.name] = field.value;
                        }}
                      />
                      <Submit />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </>
    );
  }
}

export default SampleRequestForm;
