import React from 'react';
import { ErrorMessage } from 'formik';

import Region from './region';

export default ({
  field,
  form,
  component,
  label,
  type = 'text',
  options,
  onChange,
  ...props
}) => (
  <div key={`field-row-${field.name}`}>
    <div className='col-lg-7'>
      <div className='form-group'>
        <label htmlFor={field.name}>{label}</label>
        {type === 'select' && (
          <select className='form-control' {...field} {...props}>
            {options.map(option => (
              <option key={`select-${option}`} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        {type === 'textarea' && (
          <textarea
            className='form-control'
            type='text'
            {...field}
            {...props}
          />
        )}
        {type === 'region' && (
          <Region field={field} onChange={f => onChange(f)} />
        )}
        {!['textarea', 'region', 'select'].includes(type) && (
          <input className='form-control' type={type} {...field} {...props} />
        )}
        <ErrorMessage
          name={field.name}
          render={message => <span className='text-danger'>{message}</span>}
        />
      </div>
    </div>
  </div>
);
