import React from 'react';

export default ({ alertType, message }) => (
  <div className='row mb-3'>
    <div className='col-lg-7'>
      <div className={alertType}>
        {message}
      </div>
    </div>
  </div>
);
